var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-2" }, [
        _c(
          "div",
          { staticClass: "flex justify-between" },
          [
            _c("BackNav", {
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            }),
            _c("p", { staticClass: "text-lightGrey-2 text-sm" }, [
              _vm._v("2 of 2"),
            ]),
          ],
          1
        ),
      ]),
      _c("h5", { staticClass: "md:text-2xl text-xl font-bold text-dark" }, [
        _vm._v("Confirm Details"),
      ]),
      _vm.handleTicketInZillaApp
        ? _c("p", { staticClass: "text-grey md:text-lg" }, [
            _vm._v(
              " Please, input the ticket number and the password sent to your phone number " +
                _vm._s(_vm.phone) +
                " "
            ),
          ])
        : _c("p", { staticClass: "text-grey md:text-lg" }, [
            _vm._v(
              " Follow this process to generate your ticket number and password "
            ),
            _c(
              "a",
              {
                staticClass: "text-underline text-brandPurple",
                attrs: {
                  target: "_blank",
                  href: _vm.selectedBank.ticketGenerationInstruction,
                },
              },
              [_vm._v("here")]
            ),
          ]),
      _c("InlineToast", {
        staticClass: "mt-2",
        attrs: {
          display: _vm.showError,
          type: "error",
          description: _vm.flaggedReason,
        },
      }),
      _c("InlineToast", {
        staticClass: "mt-2",
        attrs: {
          display: _vm.showResendButton && _vm.handleTicketInBoth,
          type: "info",
          description: _vm.infoText,
        },
      }),
      _c(
        "form",
        {
          staticClass: "mt-6",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.initiateConfirmation.apply(null, arguments)
            },
          },
        },
        [
          _c("Input", {
            attrs: {
              id: "ticket",
              placeholder: "Enter Ticket Number",
              label: "Ticket Number",
              width: "w-full",
              errorText: "This field cannot be empty",
              validation: _vm.rules.ticketNo,
              required: "",
            },
            model: {
              value: _vm.userData.ticketNo,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "ticketNo", $$v)
              },
              expression: "userData.ticketNo",
            },
          }),
          _c("Input", {
            staticClass: "mt-4",
            attrs: {
              id: "password",
              placeholder: "Enter Password",
              label: "Password",
              type: "password",
              width: "w-full",
              autoComplete: "new-password",
              errorText: "This field cannot be empty",
              validation: _vm.rules.password,
              required: "",
            },
            model: {
              value: _vm.userData.ticketPassword,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "ticketPassword", $$v)
              },
              expression: "userData.ticketPassword",
            },
          }),
          _c("InlineToast", {
            staticClass: "mt-10",
            attrs: {
              display: true,
              custom: "",
              type: "info",
              description:
                "Your bank will charge you N40 per page of your statement.",
            },
          }),
          _c("Button", {
            staticClass: "mt-2",
            attrs: {
              width: "w-full",
              text: "Continue",
              type: "submit",
              disabled: _vm.validateButton,
            },
          }),
          _vm.resendDetailsLoading
            ? _c("Spinner")
            : _c("div", { staticClass: "mt-4" }, [
                _vm.handleTicketInZillaApp
                  ? _c("div", [
                      !_vm.showResendButton
                        ? _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-grey text-sm mr-1" },
                                [_vm._v("Request new details in")]
                              ),
                              _c("vue-countdown", {
                                attrs: { time: _vm.requestCountdown },
                                on: {
                                  end: function ($event) {
                                    _vm.showResendButton = true
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var minutes = ref.minutes
                                        var seconds = ref.seconds
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-sm text-grey",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(minutes) +
                                                  ":" +
                                                  _vm._s(seconds)
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  733914400
                                ),
                              }),
                            ],
                            1
                          )
                        : _c("p", { staticClass: "text-brandPurple text-sm" }, [
                            _c(
                              "span",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: _vm.handleResendNewDetails },
                              },
                              [_vm._v(" Request new details")]
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ]),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.displayValidationStatus,
            hideClose: "",
            size: "large",
          },
        },
        [
          _c("VerificationStatus", {
            attrs: { type: "requestPdf", queryResult: _vm.queryResult },
            on: {
              back: function ($event) {
                _vm.displayValidationStatus = false
                _vm.$emit("back")
              },
              close: function ($event) {
                return _vm.$emit("close")
              },
              connectBank: function ($event) {
                _vm.displayValidationStatus = false
              },
              success: _vm.handleSuccess,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }