<template>
  <div>
    <div class="grid grid-cols-2">
      <div class="flex justify-between">
        <BackNav @click="$emit('back')" />
        <p class="text-lightGrey-2 text-sm">2 of 2</p>
      </div>
    </div>
    <h5 class="md:text-2xl text-xl font-bold text-dark">Confirm Details</h5>
    <p v-if="handleTicketInZillaApp" class="text-grey md:text-lg">
      Please, input the ticket number and the password sent to your phone number
      {{ phone }}
    </p>
    <p v-else class="text-grey md:text-lg">
      Follow this process to generate your ticket number and password 
      <a target="_blank" class="text-underline text-brandPurple" :href="selectedBank.ticketGenerationInstruction">here</a>
    </p>
    <InlineToast class="mt-2" :display="showError" type="error" :description="flaggedReason"/>
    <InlineToast class="mt-2" :display="showResendButton && handleTicketInBoth" type="info" :description="infoText"/>
    <form class="mt-6" @submit.prevent="initiateConfirmation">
      <Input
        id="ticket"
        placeholder="Enter Ticket Number"
        label="Ticket Number"
        width="w-full"
        errorText="This field cannot be empty"
        v-model="userData.ticketNo"
        :validation="rules.ticketNo"
        required
      />
      <Input
        id="password"
        placeholder="Enter Password"
        label="Password"
        type="password"
        width="w-full"
        class="mt-4"
        autoComplete="new-password"
        errorText="This field cannot be empty"
        v-model="userData.ticketPassword"
        :validation="rules.password"
        required
      />

      <InlineToast class="mt-10" :display="true" custom type="info" description="Your bank will charge you N40 per page of your statement."/>
      <Button width="w-full" text="Continue" class="mt-2" type="submit" :disabled="validateButton" />
      <Spinner v-if="resendDetailsLoading" />
      <div v-else class="mt-4">
        <div v-if="handleTicketInZillaApp">
            <div class="flex items-center" v-if="!showResendButton">
            <p class="text-grey text-sm mr-1">Request new details in</p>
            <vue-countdown :time="requestCountdown" v-slot="{ minutes, seconds }" @end="showResendButton = true">
              <span class="text-sm text-grey">{{ minutes }}:{{ seconds }}</span>
            </vue-countdown>
          </div>
          <p class="text-brandPurple text-sm" v-else>
            <span class="cursor-pointer" @click="handleResendNewDetails"> Request new details</span>
          </p>
        </div>
      </div>
    </form>
    <Modal :display="displayValidationStatus" hideClose size="large">
      <VerificationStatus
        @back="
          displayValidationStatus = false;
          $emit('back');
        "
        type="requestPdf"
        @close="$emit('close')"
        :queryResult="queryResult"
        @connectBank="displayValidationStatus = false"
        @success="handleSuccess"
      />
    </Modal>
  </div>
</template>
<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import { BackNav } from "@/UI/Navigation";
  import { Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { QUERY_STATEMENT_REQUEST, CONFIRM_STATEMENT_REQUEST, INITIATE_STATEMENT_REQUEST, E_CHANNEL_BANK_STATEMENT_REQUEST } from "@/utils/api/verification";
  import { STATEMENT_REQUEST_STATUS, DATA_STATUS, TICKET_CHANNELS } from "@/utils/sharedData/home";
  import { Modal } from "@/UI/Modals";
  import VueCountdown from "@chenfengyuan/vue-countdown";
  import { mdiAlertCircleOutline, mdiCircleSmall } from "@mdi/js";
  import { InlineToast } from '@/UI/Notification';

  export default {
    components: {
      BackNav,
      Input,
      Button,
      Modal,
      InlineToast,
      "vue-countdown": VueCountdown,
      VerificationStatus: () => import("../VerificationStatus.vue"),
      Spinner: () => import("@/UI/Spinner"),
    },
    props: {
      phone: {
        type: String,
        default: 0,
        required: true,
      },
      id: {
        type: String,
        default: "",
        required: true,
      },
      accountData: {
        type: Object,
        default: () => {},
        required: true,
      },
      selectedBank: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data: () => ({
      userData: {
        ticketNo: "",
        ticketPassword: "",
        accountFinancialHealthId: "",
      },
      queryResult: {},
      displayValidationStatus: false,
      queryTiming: {
        timeout: null,
        timeLeft: 120,
      },
      svgPath: {
        alert: mdiAlertCircleOutline,
        dot: mdiCircleSmall,
      },
      showError: false,
      flaggedReason: "",
      showResendButton: false,
      resendDetailsLoading: false,
      requestCountdown: 300000, //5 minutes
    }),
    computed: {
      ...mapState({
        eligibleAmount: (state) => state?.userInfo?.creditQualification?.eligibilityCriteriaResult.eligibleAmount,
        accountSummary: (state) => state?.userInfo?.accountSummary?.pendingDirectStatementRequest,
      }),
      rules() {
        return {
          ticketNo: this.userData.ticketNo.length > 0,
          password: this.userData.ticketPassword > 0,
        };
      },
      validateButton() {
        return Object.values(this.rules).includes(false);
      },
      infoText() {
        return `<p>You can follow this process to generate  your ticket number and password <a target='_blank' class="text-underline text-brandPurple" href="${this.selectedBank.ticketGenerationInstruction}">here</a></p>`;
      },
      handleTicketInZillaApp() {
        const channel = this.selectedBank.ticketGenerationChannels.includes(TICKET_CHANNELS.zilla_app);
        return channel;
      },
      handleTicketInBankApp() {
        const channel = this.selectedBank.ticketGenerationChannels.every(item => item === TICKET_CHANNELS.bank_app);
        return channel;
      },
      handleTicketInBoth() {
        const set = new Set(this.selectedBank.ticketGenerationChannels);
        if (set.has(TICKET_CHANNELS.zilla_app) && set.has(TICKET_CHANNELS.bank_app)) {
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.userData.accountFinancialHealthId = this.id;
      // let createdDate = new Date(this.accountSummary.createdAt);
      // console.log(createdDate, "summary");
      // let date = new Date().getTime();
      // console.log((createdDate.getTime() - date) / 1000);
      this.handleCountdown();
    },
    methods: {
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("userInfo", ["getCreditQualification"]),
      ...mapActions("notification", ["showToast"]),
      ...mapMutations("userInfo", ["SET_DIRECTSTATEMENT_SUMMARY"]),

      handleCountdown() {
        let createdDate = new Date(this.accountSummary.createdAt);
        let today = new Date().getTime();
        const remainingTime = (today - createdDate.getTime()) / (1000 * 60);
        // 5 represents 5 minutes
        if (remainingTime > 5) {
          this.requestCountdown = 0;
        } else {
          this.requestCountdown = (5 - remainingTime) * 60000;
        }
      },
      initiateConfirmation() {
        if (!this.validateButton) {
          this.setLoading({
            display: true,
            title: "We are analysing your bank statement",
            description: "This will only take some minutes. Please do not leave this sceeen.",
          });
          if (this.handleTicketInBankApp) {
            this.confirmStatementForBankApp();
          } else {
            this.confirmStatement();
          }
          
        } else {
          this.showToast({
            display: true,
            description: "Please fill all inputs correctly",
            icon: "error",
          });
        }
      },
      confirmStatement() {
        CONFIRM_STATEMENT_REQUEST(this.userData)
            .then(({ data }) => {
              // if the response has a result of STATEMENT_ANALYZED
              const functionToCall = () => {
                this.getCreditQualification().then(() => {
                  // this.queryResult = data?.data;
                  this.queryResult.eligibleAmount = this.eligibleAmount;
                  this.setLoading(false);
                  this.displayValidationStatus = true;
                });
              };
              if (data?.data?.directStatementRequestStatus === STATEMENT_REQUEST_STATUS.confirmed) {
                // check for STATEMENT CONFIRMED STATUS
                this.queryAccountResult(data?.data?.id, STATEMENT_REQUEST_STATUS.analyzed, functionToCall);
              }
            })
            .catch((error) => {
              this.setLoading(false);
              if (error?.response?.status === 404) {
                this.showError = true;
                this.flaggedReason = error.response.data.message;
                this.initiateLoading = false;
              }
            });
      },
      confirmStatementForBankApp() {
        const userData = {
          ticketNo: this.userData.ticketNo,
          password: this.userData.ticketPassword,
          bankId: this.accountData.bankId
        }
        E_CHANNEL_BANK_STATEMENT_REQUEST(userData)
          .then(({ data }) => {
              // if the response has a result of STATEMENT_ANALYZED
              this.queryResult = data?.data;

              const functionToCall = () => {
                this.getCreditQualification().then(() => {
                  // this.queryResult = data?.data;
                  this.queryResult.eligibleAmount = this.eligibleAmount;
                  this.setLoading(false);
                  this.displayValidationStatus = true;
                });
              };
              if (data?.data?.directStatementRequestStatus === STATEMENT_REQUEST_STATUS.confirmed) {
                // check for STATEMENT CONFIRMED STATUS
                this.queryAccountResult(data?.data?.id, STATEMENT_REQUEST_STATUS.analyzed, functionToCall);
              }
            })
            .catch((error) => {
              this.setLoading(false);
              if (error?.response?.status === 404) {
                this.showError = true;
                this.flaggedReason = error.response.data.message;
                this.initiateLoading = false;
              }
            });
      },
      queryAccountResult(id, statusToCheck, functionToCall) {
        if (this.queryTiming.timeLeft === 120) {
          this.startQueryTimeout();
        }
        // if the 2mins timeout has passed
        if (this.queryTiming.timeLeft <= 0) {
          this.setLoading(false);
          this.queryResult.dataStatus = DATA_STATUS.pending;
          this.displayValidationStatus = true;
        } else {
          QUERY_STATEMENT_REQUEST(id)
            .then(({ data }) => {
              if (data?.data?.directStatementRequestStatus === STATEMENT_REQUEST_STATUS.failed) {
                this.showError = true;
                this.flaggedReason = this.flaggedReason = data?.data.flaggedReason ? data?.data.flaggedReason : "Unable to get your information";
                this.queryResult = data?.data
                this.displayValidationStatus = true;
                clearTimeout(this.queryCounter);
                clearInterval(this.queryTiming.timeout);
                this.setLoading(false);
              } else if (data.data.directStatementRequestStatus === statusToCheck) {
                this.queryResult = data?.data
                functionToCall();
              } else {
                // recurse the function
                this.queryCounter = setTimeout(() => {
                  this.queryAccountResult(id, statusToCheck, functionToCall);
                }, 2000);
              }
            })
            .catch((error) => {
              this.setLoading(false);
              if (this.queryTiming.timeLeft > 0) {
                clearInterval(this.queryTiming.timeout);
                this.queryTiming.timeLeft = 120;
              }
              if (error?.response?.status === 404) {
                this.showError = true;
                this.flaggedReason = error.response.data.message;
                this.initiateLoading = false;
              }
            });
        }
      },
      startQueryTimeout() {
        this.queryTiming.timeout = setInterval(() => {
          if (this.queryTiming.timeLeft <= 0) {
            clearInterval(this.queryTiming.timeout);
          }
          this.queryTiming.timeLeft -= 1;
        }, 1000);
      },
      handleSuccess() {
        this.$emit("success");
      },
      handleResendNewDetails() {
        this.setLoading({
          display: true,
          title: "We are processing your request",
          description: "This will only take some minutes. Please do not leave this sceeen.",
        });
        this.showResendButton = false;
        INITIATE_STATEMENT_REQUEST(this.accountData)
          .then(({ data }) => {
            this.SET_DIRECTSTATEMENT_SUMMARY(data.data);
            this.resendDetailsLoading = false;
            const functionToCall = () => {
              this.showToast({
                display: true,
                description: "Ticket details sent",
                icon: "success",
              });
              this.handleCountdown();
              this.setLoading(false);
            };
            if (data?.data?.directStatementRequestStatus === STATEMENT_REQUEST_STATUS.requested) {
              this.queryAccountResult(data.data.id, STATEMENT_REQUEST_STATUS.sent, functionToCall);
            } else {
              this.showError = true;
              this.flaggedReason = data?.data.flaggedReason ? data?.data.flaggedReason : "Unable to get your information";
              this.setLoading(false);
              this.showResendButton = true;
            }
          })
          .catch(() => {
            // this.resendDetailsLoading = false;
            this.setLoading(false);
          });
      },
    },
    destroyed() {
      clearTimeout(this.queryCounter);
      clearInterval(this.queryTiming.timeout);
    },
  };
</script>

